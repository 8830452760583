import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';

import PartnerSectionWrapper from './partner.style';
import Partner from '../../common/assets/SampleReportImage.png';

const PartnerSection = ( {
    row,
    col,
    title,
    description,
    button,
    textArea,
    imageArea,
    clientConfig
} ) => {

    const handleProceedToReport = () => {
        const baseUrl = 'https://stride-report-assets.s3.ap-south-1.amazonaws.com/letsunbound/FILE/13910-24800-report.pdf';
        window.open( baseUrl );
    }

    return (
        <PartnerSectionWrapper id='report'>
            <Container width="1400px">
                <Box { ...row }>
                    <Box { ...col } { ...textArea }>
                        <Heading
                            { ...title }
                            content="Uncover a World of Opportunities with Your Personalised Ivy League Readiness Report"
                        />
                    </Box>
                    <Box { ...col } { ...imageArea }>
                        <Image src={ Partner } alt="Domain Image" preview={ false } />
                    </Box>
                    <Box>
                        <button onClick={ handleProceedToReport } style={ { backgroundColor: clientConfig?.primary_color, border: 'none', cursor: 'pointer', color: '#FFFFFF', fontSize: '24px', fontWeight: '600', padding: '1rem 2rem', borderRadius: '5px' } }>
                            See Sample Report
                        </button>
                    </Box>
                </Box>
            </Container>
        </PartnerSectionWrapper>
    );
};

PartnerSection.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    title: PropTypes.object,
    description: PropTypes.object,
    button: PropTypes.object,
    textArea: PropTypes.object,
    imageArea: PropTypes.object,
    clientConfig: PropTypes.object,
};

PartnerSection.defaultProps = {
    row: {
        flexBox: true,
        ml: '-15px',
        mr: '-15px',
        alignItems: 'center',
        flexDirection: 'column'
    },
    imageAreaRow: {
        flexDirection: 'row-reverse',
    },
    col: {
        pr: '15px',
        pl: '15px',
    },
    textArea: {
        width: ['100%', '100%', '60%', '60%', '60%'],
    },
    imageArea: {
        width: ['100%', '100%', '45%', '50%', '58%'],
        mt: ['40px', '40px', '0', '0', '0'],
    },
    title: {
        fontSize: ['26px', '30px', '30px', '48px', '48px'],
        fontWeight: '700',
        textAlign: 'center',
        color: '#000000',
        letterSpacing: '-0.025em',
        mb: '15px',
        lineHeight: '1.25',
    },
    description: {
        fontSize: '18px',
        color: '#504E4E',
        lineHeight: '1.75',
        mb: '33px',
    },
    button: {
        fontSize: '18px',
        fontWeight: '500',
    },
};

export default PartnerSection;